"use client";

import React from "react";
import {sendGAEvent} from "@next/third-parties/google";

export default function AmazonBookButton() {
  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const eventName = "amazon_book_click";

    if (typeof window !== "undefined" && typeof window.ym === "function") {
      window.ym(94764572, "reachGoal", eventName);
    }

    sendGAEvent("event", eventName);

    window.open("https://www.amazon.com/dp/B0DTLXDLVQ", "_blank", "noopener,noreferrer");
  }

  return (
    <button
      onClick={handleClick}
      className="inline-block px-6 py-3 bg-gold text-black font-bold text-2xl sm:text-3xl md:text-4xl font-tungsten-semibold uppercase hover:bg-black hover:text-gold hover:shadow-lg transform hover:scale-105 transition-all duration-300">
      Available on Amazon
    </button>
  );
}
