"use client";

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import Link from "next/link";

/** Controls the text sizes in Tailwind. */
type TextSizeOption = "xxsmall" | "xsmall" | "small" | "medium" | "large";
/** Controls button styling (e.g., default vs red/danger). */
type ButtonVariant = "default" | "danger" | "gray";

interface MozgButtonProps {
  title: string;
  /** If provided, uses a Next.js Link instead of a button. */
  url?: string;
  icon?: IconDefinition;
  iconSize?: SizeProp;
  textSize?: TextSizeOption;
  /** If no URL is provided, onClick is used on a <button>. */
  onClick?: () => void;
  /** Disables the button's click action & styling if true. */
  disabled?: boolean;
  /** Choose between default primary or red danger style. */
  variant?: ButtonVariant;
  isSubmit?: boolean;
}

/** Returns Tailwind text-size classes based on the textSize option */
function getTextSizeClass(size: TextSizeOption = "medium"): string {
  switch (size) {
    case "xxsmall":
      return "text-lg sm:text-xl md:text-2xl";
    case "xsmall":
      return "text-xl sm:text-2xl md:text-3xl";
    case "small":
      return "text-2xl sm:text-3xl md:text-4xl";
    case "large":
      return "text-4xl sm:text-5xl md:text-6xl";
    case "medium":
    default:
      return "text-3xl sm:text-4xl md:text-5xl";
  }
}

/** Returns Tailwind padding classes based on the textSize option */
function getPaddingClass(size: TextSizeOption = "medium"): string {
  switch (size) {
    case "xxsmall":
      return "px-2"; // minimal padding
    case "xsmall":
      return "px-3 py-2";
    case "small":
      return "px-4 py-3";
    case "large":
      return "px-10 py-5";
    case "medium":
    default:
      return "px-8 py-4";
  }
}

export default function MozgButton({title, url, icon, iconSize = "xs", textSize = "medium", onClick, disabled = false, variant = "default", isSubmit = false}: MozgButtonProps) {
  // 1) Determine text size & padding
  const textSizeClasses = getTextSizeClass(textSize);
  const paddingClasses = getPaddingClass(textSize);

  // 2) If text is small or xsmall, reduce icon size further
  if (textSize === "small" || textSize === "xsmall") {
    iconSize = "2xs";
  } else if (textSize === "xxsmall") {
    iconSize = "sm";
  }

  // 3) Base styling for non-disabled
  //    - default=primary, danger=red
  let enabledBgColor = "bg-primary text-black hover:bg-black hover:text-primary";
  if (variant === "danger") {
    enabledBgColor = "bg-gray-600 text-white hover:bg-black ";
  } else if (variant === "gray") {
    enabledBgColor = "bg-gray-600 text-white hover:bg-black ";
  }

  // 4) Disabled styling
  const disabledBgColor = "bg-gray-500 text-gray-300 cursor-not-allowed";

  // 5) Base classes
  let baseClasses = `
    inline-flex items-center justify-center font-light shadow-md
    font-tungsten-semibold uppercase transform transition-all duration-300
  `;
  if (!disabled) {
    baseClasses += " hover:shadow-lg hover:scale-105";
  }

  // 6) Combine everything
  const classes = `
    ${baseClasses}
    ${paddingClasses}
    ${textSizeClasses}
    ${disabled ? disabledBgColor : enabledBgColor}
  `;

  // 7) Button content (title + optional icon)
  const content = (
    <>
      {title}
      {icon && (
        <span className={`${textSize === "xxsmall" ? "pl-2" : "pl-4"}`}>
          <FontAwesomeIcon icon={icon} size={iconSize} />
        </span>
      )}
    </>
  );

  // 8) If a URL is provided & not disabled => Next.js Link
  if (url && !disabled) {
    return (
      <Link href={url} rel="noopener noreferrer" className={classes}>
        {content}
      </Link>
    );
  }

  // 9) If URL is provided BUT disabled => <span>
  if (url && disabled) {
    return <span className={classes}>{content}</span>;
  }

  // 10) Otherwise => normal <button>
  return (
    <button type={isSubmit ? "submit" : "button"} onClick={onClick} className={classes} disabled={disabled}>
      {content}
    </button>
  );
}
