import(/* webpackMode: "eager", webpackExports: ["default"] */ "/azp/_work/2/s/app/components/AmazonBookButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/azp/_work/2/s/app/components/ContactFormSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/azp/_work/2/s/app/components/DynamicEventButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/azp/_work/2/s/app/components/MozgButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/azp/_work/2/s/app/components/VideoPlayerClient.tsx");
;
import(/* webpackMode: "eager" */ "/azp/_work/2/s/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/azp/_work/2/s/node_modules/next/dist/client/image-component.js");
